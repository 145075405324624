import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SubstrPipe } from './pipes/substr/substr.pipe';
import { TimeagoPipe } from './pipes/timeago/timeago.pipe';
import { GobackDirective } from './directives/goback/goback.directive';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { ClipboardDirective } from './directives/clipboard/clipboard.directive';

const elements = [
  SubstrPipe,
  TimeagoPipe,
  ClipboardDirective,
  GobackDirective,
];

const modules = [
  HttpClientModule,
  MatDialogModule,
  ReactiveFormsModule,
  MatAutocompleteModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatSelectModule,
  MatMenuModule,
]

@NgModule({
  imports: [
    CommonModule,
    ...modules,
  ],
  providers: [
    DatePipe,
  ],
  declarations: [
    ...elements,
  ],
  exports: [
    ...elements,
    ...modules,
  ]
})
export class SharedModule { }
