import { Injectable } from '@angular/core';
import { delay, firstValueFrom, Observable, of } from 'rxjs';
import getHours from 'date-fns/getHours';
import { Map } from '@models/commons.model';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  constructor() { }

  public greetingsText(): string {
    const date = new Date();
    const hour = getHours(date);

    if (hour > 5 && hour < 12) {
      return 'Good morning';
    } else if (hour < 16) {
      return 'Good afternoon';
    } else if (hour < 22) {
      return 'Good evening';
    }

    return 'Hey';
  }

  public delay(count: number): Promise<void> {
    return firstValueFrom(this.delay$(count).pipe(
    ));
  }

  public delay$(count: number): Observable<void> {
    return of(undefined).pipe(delay(count));
  }

  public getRandomString(size: number = 50, chars?: string, specialChars?: boolean): string {
    const result: string[] = [];
    const possibleCharacters = (chars || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789') + (specialChars ? '@#$%^&*()_+-=!{}|:;,./?><' : '');
    const charSize = possibleCharacters.length;

    for (let i = 0; i < size; i++) {
      result.push(
        possibleCharacters.charAt(Math.floor(Math.random() * charSize))
      );
    }

    return result.join('');
  }

  public appendQueryString(url: string, params: Map<any>): string {
    let result = url + '?';

    for (const field in params) {
      if (params[field] !== undefined) {
        result += `${field}=${params[field]}&`;
      }
    }

    return result.substring(0, result.length - 1);
  }

  public loadExternalScripts(): void {
    const scripts: { src: string; integrity?: string; cors?: string; }[] = [
      // {
      //   src: 'https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js',
      //   integrity: 'sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p',
      //   cors: 'anonymous'
      // },
      // { src: 'https://cdn.jsdelivr.net/npm/chart.js@3.8.2/dist/chart.min.js' }
    ];

    for (const script of scripts) {
      const elem = document.createElement('script');
      elem.type = 'text/javascript';
      elem.src = script.src;
      elem.crossOrigin = script.cors || '';
      elem.integrity = script.integrity || '';
      elem.async = true;

      document.body.appendChild(elem);
    }
  }

  public loadExternalStyleSheets(): void {
    const stylesheets: { url: string; }[] = [
      {
        url: 'https://cdn.materialdesignicons.com/7.1.96/css/materialdesignicons.min.css'
      },
      {
        url: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap',
      },
      {
        url: 'https://fonts.googleapis.com/icon?family=Material+Icons',
      },
      {
        url: 'https://fonts.googleapis.com/css?family=Sofia'
      },
      {
        url: 'https://fonts.googleapis.com/css?family=Bahianita'
      }
    ];

    for (const st of stylesheets) {
      const elem = document.createElement('link');
      elem.href = st.url;
      elem.rel = 'stylesheet';

      document.body.appendChild(elem);
    }
  }
}
