import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApiResponse, Map } from '@models/commons.model';
import isAfter from 'date-fns/isAfter';
import subMilliseconds from 'date-fns/subMilliseconds';
import keys from 'lodash-es/keys';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { UtilitiesService } from '../utilities/utilities.service';
import { ApiService } from '../api/api.service';

export interface CacheDataFormat<T> {
  id: string;
  obs$: BehaviorSubject<T>;
  lastDate: Date;
}

export interface CachedRequestOpts {
  cacheTimeInMs?: number;
  reset?: boolean;
  skipErrorCheck?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cache: Map<CacheDataFormat<any>> = {};

  constructor(
    private http: HttpClient,
    private utilities: UtilitiesService,
    private api: ApiService,
  ) { }

  public clearCache(): void {
    keys(this.cache).forEach(key => {
      this.cache[key].obs$.unsubscribe();
    });

    this.cache = {};
  }

  private setData<T>(path: string, value: T): void {
    if (!this.cache[path]) {
      this.cache[path] = {
        lastDate: undefined as any,
        id: path,
        obs$: new BehaviorSubject(undefined),
      };
    }

    this.cache[path].lastDate = new Date();
    this.cache[path].obs$.next(value);
  }

  private getData<T>(path: string): Observable<T> {
    if (!this.cache[path]) {
      this.setData(path, undefined);
    }

    return this.cache[path].obs$.asObservable().pipe(
      filter(a => a !== undefined),
    );
  }

  public handleRequest<T>(path: string, params?: Map<any>, opts: CachedRequestOpts = {}): Observable<ApiResponse<T>> {
    const url = this.utilities.appendQueryString(path, params || {});

    if (opts.reset) {
      this.cache[url] ? this.cache[url].lastDate = new Date(0) : undefined;
      this.cache[url]?.obs$.next(undefined);
    }

    const delay = opts.cacheTimeInMs || environment.storeDefaultCacheTimeInMilliSeconds;
    const ago = subMilliseconds(new Date(), delay);
    const lastDate = this.cache[url]?.lastDate || new Date(0);

    const isActive = isAfter(lastDate, ago);
    if (!isActive) {
      if (this.cache[url]) {
        this.cache[url].lastDate = new Date();
      }

      this.api.get<T>(url).subscribe({
        next: res => {
          this.setData<any>(url, res);
        },
        error: err => {
          if (err.status === 403 && !opts.skipErrorCheck) {
            console.log(err, err);
          } else {
            this.setData<any>(url, err.error);
          }
        }
      });
    }

    return this.getData<ApiResponse<T>>(url);
  }
}
