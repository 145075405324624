export const environment = {
  firebase: {
    apiKey: 'AIzaSyDIGG-Wlp8d1cB5sCoyx8vGEszCPoL0DUc',
    authDomain: 'gospel-to-the-world.firebaseapp.com',
    projectId: 'gospel-to-the-world',
    storageBucket: 'gospel-to-the-world.appspot.com',
    messagingSenderId: '735846106668',
    appId: '1:735846106668:web:158cd4d8260596d2503389',
    measurementId: 'G-Z36J77ZEFQ',
  },
  production: true,
  serverApiUrl: 'https://translator-dev-server.gospeltotheworlds.com/api/v1',
  storeDefaultCacheTimeInMilliSeconds: 5000,
};
