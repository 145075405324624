import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertComponent } from '@shared/components/modals/alert/alert.component';
import { ConfirmComponent } from '@shared/components/modals/confirm/confirm.component';
import { AlertDialogOptions, ConfirmDialogOptions, DialogOptions } from '@shared/models/client.model';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  public isMobile = window.innerWidth <= 767;

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
    this.setScreenSize();
  }

  private setScreenSize() {
    window.addEventListener('resize', _w => {
      this.isMobile = window.innerWidth <= 767;
    });
  }

  public toast(msg: string, action?: string, duration?: number): Promise<void> {
    return new Promise(resolve => {
      const ref = this.snackBar.open(msg, action, { duration: duration || 5000 });

      ref.afterDismissed().subscribe(_ => {
        resolve();
      });
    });
  }

  public alert(data: AlertDialogOptions, opts: DialogOptions = {}): Promise<void> {
    return new Promise(resolve => {
      const ref = this.dialog.open(AlertComponent, { data, ...opts },);
      ref.disableClose = true;

      ref.afterClosed().subscribe(_ => {
        resolve();
      });
    });
  }

  public confirm(data: ConfirmDialogOptions, opts: DialogOptions = {}): Promise<boolean> {
    return new Promise(resolve => {
      const ref = this.dialog.open(ConfirmComponent, { data, ...opts });
      ref.disableClose = true;

      ref.afterClosed().subscribe((st: boolean) => {
        resolve(st);
      });
    });
  }

  public launchDialog<C, T, R>(component: ComponentType<C>, data: T, opts: DialogOptions = {}): Promise<R> {
    return new Promise(resolve => {
      const ref = this.dialog.open(component, { data, ...opts, maxWidth: '95vw' });
      ref.disableClose = true;

      ref.afterClosed().subscribe((res: R) => {
        resolve(res);
      });
    });
  }
}
