import { Location } from '@angular/common';
import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[gobackDir]'
})
export class GobackDirective implements OnInit {
  constructor(
    private location: Location,
    private ref: ElementRef,
  ) { }

  ngOnInit(): void {
    this.ref.nativeElement.onclick = () => {
      this.location.back();
    }
  }
}
