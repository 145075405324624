import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertDialogOptions } from '@shared/models/client.model';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogOptions,
    private dialogRef: MatDialogRef<AlertComponent>,
  ) { }

  ngOnInit(): void { }

  public closeModal(): void {
    this.dialogRef.close();
  }
}
