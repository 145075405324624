import { Injectable, NgZone } from '@angular/core';
import { Observable, BehaviorSubject, firstValueFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { UtilitiesService } from '@services/utilities/utilities.service';
import {
  onAuthStateChanged, signInWithEmailAndPassword,
  AuthCredential, EmailAuthProvider,
  reauthenticateWithCredential, sendEmailVerification,
  sendPasswordResetEmail, signOut, updateEmail,
  updatePassword, User as FirebaseUser, UserCredential, Auth, GoogleAuthProvider, AuthProvider, signInWithPopup,
} from '@angular/fire/auth';
import { User } from '@models/user.model';
import { ApiResponse, Map } from '@models/commons.model';
import { CacheService } from '../cache/cache.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user!: FirebaseUser;

  private authState$: BehaviorSubject<FirebaseUser> = new BehaviorSubject(undefined as any);

  public constructor(
    private zone: NgZone,
    private apiService: ApiService,
    private utilitiesService: UtilitiesService,
    private auth: Auth,
    private cache: CacheService,
  ) {
    onAuthStateChanged(this.auth, async (user) => {
      if (!this.user && user) {
        await user.getIdToken(true);
      }

      this.user = user as FirebaseUser;
      this.zone.run(() => this.authState$.next(this.user));
    });
  }

  public isLoggedIn(): Promise<boolean> {
    return firstValueFrom(this.getUser().pipe(
      map(user => !!user),
    ));
  }

  public getUser(): Observable<FirebaseUser> {
    return this.authState$.pipe(
      filter(user => user !== undefined)
    );
  }

  public register(model: Map<any>, token: string): Promise<ApiResponse<User>> {
    return this.apiService.post<User>('users', model, {}, { recaptchaToken: token });
  }

  public async login(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.auth, email, password).then(a => {
      this.user = a.user as FirebaseUser;
      this.authState$.next(a.user);
      return a;
    });
  }

  public logout(): Promise<void> {
    this.cache.clearCache();
    return signOut(this.auth);
  }

  public async watchEmailVerification(): Promise<void> {
    if (this.user?.emailVerified) {
      await this.user?.getIdToken(true);
      return Promise.resolve();
    }

    await this.user?.reload();
    await this.utilitiesService.delay(5000);

    return this.watchEmailVerification();
  }

  public sendVerificationEmail(waitForComplete?: boolean): Observable<any> {
    return new Observable((observer) => {
      sendEmailVerification(this.user).then(() => {
        if (waitForComplete) {
          this.watchEmailVerification().then(() => observer.next());
        } else {
          observer.next();
        }
      }).catch((err: any) => {
        throw Error(err);
      });
    });
  }

  public resetPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email);
  }

  public getUserToken() {
    return this.user?.getIdToken(true);
  }

  private getPasswordCredential(email: string, password: string): AuthCredential {
    return EmailAuthProvider.credential(email, password);
  }

  public async changeEmailAddress(oldEmail: string, newEmail: string, password: string): Promise<void> {
    const credential = this.getPasswordCredential(oldEmail, password);

    await reauthenticateWithCredential(this.user, credential);
    return updateEmail(this.user, newEmail);
  }

  public async changePassword(email: string, oldpass: string, newpass: string): Promise<void> {
    const credential = this.getPasswordCredential(email, oldpass);

    await reauthenticateWithCredential(this.user, credential);
    return updatePassword(this.user, newpass);
  }

  // Sign in with Google
  public loginWithGoogle() {
    return this.authLogin(new GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  private authLogin(provider: AuthProvider): Promise<{ cred?: UserCredential; error?: any }> {
    return new Promise(resolve => {
      signInWithPopup(this.auth, provider)
        .then((cred) => {
          resolve({ cred });
        })
        .catch((error) => {
          resolve({ error });
        });
    });
  }
}
