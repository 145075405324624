<app-modal-container>
  <div class="modal_header">
    <h2>{{data.title || 'Alert'}}</h2>
  </div>

  <div class="modal_body">
    <div [innerHTML]="data.msg"></div>
  </div>

  <div class="modal_footer">
    <button mat-raised-button [color]="data.actionBtnColor || 'primary'" (click)="closeModal()">
      {{data.actionBtn || 'Okay!'}}
    </button>
  </div>
</app-modal-container>