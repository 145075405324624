import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { DialogService } from '@services/dialog/dialog.service';

@Directive({
  selector: '[appClipboard]'
})
export class ClipboardDirective implements OnInit {
  @Input() appClipboard!: string;

  constructor(
    private element: ElementRef,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.element.nativeElement.onclick = async () => {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(this.appClipboard);
        this.dialogService.toast('Copied to clipboard');
      } else {
        this.dialogService.toast('Action not supported on this device/browser');
      }
    }
  }
}
