import { Injectable } from '@angular/core';
import { ContactMessage } from '@models/contact.model';
import { LoginTypes, User } from '@models/user.model';
import { ApiService } from '../api/api.service';
import { CacheService } from '../cache/cache.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private api: ApiService,
    private cache: CacheService,
  ) { }

  public getCurrentUser() {
    return this.cache.handleRequest<User>('users/get/current-user');
  }

  // endpoint always called whenever user is authenticated to create db user (if new) or update last login date (if already exists)
  public upsertCurrentUser(type: LoginTypes, user: User) {
    return this.api.post<User>('auth/users/upsert', { type, user });
  }

  public updateUser(user: User) {
    return this.api.put<User>(`users/${user.id}`, user);
  }

  public saveContactMessage(payload: ContactMessage) {
    return this.api.post<void>('utilities/contact/submit', { payload });
  }
}
