<app-modal-container>
  <div class="modal_header">
    <h2 mat-dialog-title>{{data.title || 'Confirm action'}}</h2>
  </div>

  <div class="modal_body">
    <div [innerHTML]="data.msg"></div>
  </div>

  <div class="modal_footer">
    <button mat-stroked-button [color]="data.noBtnColor || 'warn'" (click)="closeModal(false)">
      {{data.noBtn || 'No'}}
    </button>

    <button mat-raised-button [color]="data.yesBtnColor || 'primary'" (click)="closeModal(true)">
      {{data.yesBtn || 'Yes'}}
    </button>
  </div>
</app-modal-container>