import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, Map } from '@models/commons.model';
import { firstValueFrom, from, Observable } from 'rxjs';
import { filter, map, mergeAll } from 'rxjs/operators';
import { environment } from '@env/environment';
import { authState, getAuth } from '@angular/fire/auth';
import { APISpecialActionsOptions } from '@models/client.model';
import { TimestampDate } from 'timestamp-date';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private timeStamp = new TimestampDate();

  constructor(
    private http: HttpClient,
  ) { }

  private apiConfig(params: Map<any>, opts: APISpecialActionsOptions): Observable<{ config: Map<any>; }> {
    return authState(getAuth()).pipe(
      filter(u => u !== undefined),
      map(user => {
        return from(user ? user.getIdToken() : Promise.resolve(''));
      }),
      mergeAll(),
      map(token => {
        const config = {
          headers: {
            'Authorization': 'Bearer ' + token,
            'x-recaptcha-token': opts.recaptchaToken || '',
            'x-translator-platform': 'web',
          },
          params: params || {}
        };

        return { config };
      }),
    );
  }

  private handleApi<T>(params: Map<any>, mapper: (r: { config: Map<any> }) => Observable<ApiResponse<T>>, opts?: APISpecialActionsOptions): Observable<ApiResponse<T>> {
    return this.apiConfig(params || {}, opts || {}).pipe(
      map(mapper),
      mergeAll(),
      map(r => {
        if (r.success) {
          r.data = this.timeStamp.parseStringToDate(r.data);
        } else {
          console.log(r);

          // r.message = r.stackTrace?.[0]?.msg || r.message || 'errorEncountered';
        }

        return r;
      }),
    );
  }

  private toUrl(path: string): string {
    return environment.serverApiUrl + '/' + path.substring(path.startsWith('/') ? 1 : 0);
  }

  public get<T>(url: string, params?: Map<any>, opts?: APISpecialActionsOptions): Observable<ApiResponse<T>> {
    return this.handleApi(
      params || {},
      (r => {
        return this.http.get<ApiResponse<T>>(this.toUrl(url), r.config);
      }),
      opts,
    );
  }

  public post<T>(url: string, body?: Map<any>, params?: Map<any>, opts?: APISpecialActionsOptions): Promise<ApiResponse<T>> {
    return new Promise(resolve => {
      firstValueFrom(
        this.handleApi(
          params || {},
          (r => {
            return this.http.post<ApiResponse<T>>(this.toUrl(url), body || {}, r.config);
          }),
          opts,
        )
      ).then(res => {
        resolve(res);
      }).catch(e => {
        console.log(e);

        resolve(e.error);
      });
    });
  }

  public put<T>(url: string, body?: Map<any>, params?: Map<any>, opts?: APISpecialActionsOptions): Promise<ApiResponse<T>> {
    return new Promise(resolve => {
      firstValueFrom(
        this.handleApi(
          params || {},
          (r => {
            return this.http.put<ApiResponse<T>>(this.toUrl(url), body || {}, r.config);
          }),
          opts,
        )
      ).then(res => {
        resolve(res);
      }).catch(e => {
        resolve(e.error);
      });
    })
  }

  public delete<T>(url: string, params?: Map<any>, opts?: APISpecialActionsOptions): Promise<ApiResponse<T>> {
    return new Promise(resolve => {
      firstValueFrom(
        this.handleApi(
          params || {},
          (r => {
            return this.http.delete<ApiResponse<T>>(this.toUrl(url), r.config);
          }),
          opts,
        )
      ).then(res => {
        resolve(res);
      }).catch(e => {
        resolve(e.error);
      });
    });
  }
}
