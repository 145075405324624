<div class="modal">
  <div mat-dialog-title>
    <ng-content select=".modal_header"></ng-content>
  </div>

  <mat-dialog-content class="mat-typography">
    <ng-content select=".modal_body"></ng-content>
  </mat-dialog-content>

  <div class="footer_section">
    <mat-dialog-actions align="end">
      <ng-content select=".modal_footer"></ng-content>
    </mat-dialog-actions>
  </div>
</div>